/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createAction, props } from "@ngrx/store";
import { GetBrandingResponse } from "../model/get-branding.response";
import { themeEnum } from "../model/themeEnum";
import { Country } from "../model/country";
import { Currency } from "../model/currency";
import { MenuState } from "../model/layout.state";
import { User } from "../../shared-services/user/model/authenticate.request";

const LAYOUT_PREFIX = `[LAYOUT]`;

export const GET_BRANDING: string = `${LAYOUT_PREFIX} GET_BRANDING`;
export const SET_BRANDING: string = `${LAYOUT_PREFIX} SET_BRANDING`;
export const SET_THEME: string = `${LAYOUT_PREFIX}  SET_THEME`;
export const SET_COUNTRY: string = `${LAYOUT_PREFIX}  SET_COUNTRY`;
export const SET_CURRENCY_CODE: string = `${LAYOUT_PREFIX}  SET_CURRENCY_CODE`;
export const GET_CURRENCY_INFO: string = `${LAYOUT_PREFIX}  GET_CURRENCY_INFO`;
export const SET_CURRENCY_INFO: string = `${LAYOUT_PREFIX}  SET_CURRENCY_INFO`;
export const SET_LANGUAGE: string = `${LAYOUT_PREFIX}  SET_LANGUAGE`;
export const SET_MENU_STATE: string = `${LAYOUT_PREFIX}  SET_MENU_STATE`;

export const getBranding = createAction(GET_BRANDING);
export const setBranding = createAction(
  SET_BRANDING,
  props<{ branding: GetBrandingResponse }>(),
);
export const setTheme = createAction(SET_THEME, props<{ theme: themeEnum }>());
export const setCountry = createAction(
  SET_COUNTRY,
  props<{ country: Country }>(),
);
export const setCurrencyCode = createAction(
  SET_CURRENCY_CODE,
  props<{ currencyCode: string }>(),
);
export const getCurrencyInfo = createAction(GET_CURRENCY_INFO);
export const setCurrencyInfo = createAction(
  SET_CURRENCY_INFO,
  props<{ currencyInfo: Currency }>(),
);
export const setLanguage = createAction(
  SET_LANGUAGE,
  props<{ language: string }>(),
);

export const setMenuState = createAction(
  SET_MENU_STATE,
  props<{ menuState: MenuState }>(),
);

/*



export const SET_COUNTRY: string = `${LAYOUT_PREFIX} SET_COUNTRY`;


export const SET_CURRENCY_CODE: string = `${LAYOUT_PREFIX}  SET_CURRENCY_CODE`;
export const GET_CURRENCY_INFO: string = `${LAYOUT_PREFIX}  GET_CURRENCY_INFO`;
export const GET_CURRENCY_SUCCESS: string = `${LAYOUT_PREFIX}  GET_CURRENCY_SUCCESS`;
export const CREATE_INIT_CART: string = `${LAYOUT_PREFIX}  CREATE_INIT_CART`;
export const GET_CART: string = `${LAYOUT_PREFIX}  GET_CART`;
export const SET_CART: string = `${LAYOUT_PREFIX}  SET_CART`;
export const SET_CARTS_CODE: string = `${LAYOUT_PREFIX}  SET_CARTS_CODE`;
export const UPDATE_NAME_CART: string = `${LAYOUT_PREFIX}  UPDATE_NAME_CART`;
export const THERE_IS_A_RESERVATION: string = `${LAYOUT_PREFIX}  THERE_IS_A_RESERVATION`;
export const SET_RESERVATION_STATE: string = `${LAYOUT_PREFIX}  SET_RESERVATION_STATE`;
/!*export const GET_ALL_DATA_HOME: string = `${LAYOUT_PREFIX}  GET_ALL_DATA_HOME`;
export const SET_ALL_DATA_HOME: string = `${LAYOUT_PREFIX}  SET_ALL_DATA_HOME`;
export const LOADING_HOME: string = `${LAYOUT_PREFIX}  LOADING_HOME`;
export const GET_COMPANY_IMAGES: string = `${LAYOUT_PREFIX}  GET_COMPANY_IMAGES`;
export const SET_COMPANY_IMAGES: string = `${LAYOUT_PREFIX}  SET_COMPANY_IMAGES`;*!/
export const GET_NAVIGATION: string = `${LAYOUT_PREFIX}  GET_NAVIGATION`;
export const SET_NAVIGATION: string = `${LAYOUT_PREFIX}  SET_NAVIGATION`;

export const setCountry = createAction(
  SET_COUNTRY,
  props<{ country: Country }>()
);


export const setCurrencyCode = createAction(
  SET_CURRENCY_CODE,
  props<{ currencyCode: string }>()
);

export const getCurrencySuccess = createAction(
  GET_CURRENCY_SUCCESS,
  props<{ currency: Currency }>()
);
export const getCart = createAction(GET_CART);
export const setCart = createAction(
  SET_CART,
  props<{ cart: ShoppingCart; index: number }>()
);
export const setCartsCode = createAction(
  SET_CARTS_CODE,
  props<{ cart: ShoppingCart[] }>()
);
export const createCart = createAction(
  CREATE_INIT_CART,
  props<{ cart: ShoppingCart; index: number }>()
);
export const updateNameCart = createAction(
  UPDATE_NAME_CART,
  props<{ newCart: ShoppingCart; index: number }>()
);
export const thereIsAReservation = createAction(
  THERE_IS_A_RESERVATION,
  props<{ reservationCode: string; lastName: string }>()
);
export const setReservationState = createAction(
  SET_RESERVATION_STATE,
  props<{ thereIsAReservation: thereIsAReservationEnum }>()
);
export const getNavigation = createAction(
  GET_NAVIGATION,
  props<{ companyId: string }>()
);
export const setMenu = createAction(
  SET_NAVIGATION,
  props<{ menu: MenuLink[] }>()
);
/!*
export const getAllDataHome = createAction(GET_ALL_DATA_HOME);
export const setAllDataHome = createAction(SET_ALL_DATA_HOME, props<{ homeData: allDataHome }>());
export const setLoadingHome = createAction(LOADING_HOME, props<{ state: boolean }>());
export const getCompanyImages = createAction(GET_COMPANY_IMAGES);
export const setCompanyImages = createAction(SET_COMPANY_IMAGES, props<{ images: ICmpMedia }>());
*!/
*/

export const LOGIN: string = `${LAYOUT_PREFIX} LOGIN`;
export const SET_USER: string = `${LAYOUT_PREFIX} SET_USER`;
export const LOGOUT: string = `${LAYOUT_PREFIX} LOGOUT`;
export const REFRESH_TOKEN: string = `${LAYOUT_PREFIX} REFRESH_TOKEN`;
export const CLEAR_USER: string = `${LAYOUT_PREFIX} CLEAR_USER`;

export const login = createAction(
  LOGIN,
  props<{ username: string; password: string }>(),
);
export const refreshToken = createAction(REFRESH_TOKEN);
export const setUser = createAction(SET_USER, props<{ user: User }>());

export const logout = createAction(LOGOUT);
export const clearUser = createAction(CLEAR_USER);
