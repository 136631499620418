/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LayoutState } from "../model/layout.state";
import moment from "moment";
import { UserState } from "../../routable-components/public/login/model/login.model";
import { User } from "../../shared-services/user/model/authenticate.request";
import { layoutFeatureName } from "./layout.reducers";
import { Country } from "../model/country";
import { themeEnum } from "../model/themeEnum";

export const selectLayoutState =
  createFeatureSelector<LayoutState>(layoutFeatureName);
export const selectLogo = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.logo || "",
);
export const selectMenu = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.menu,
);
export const selectNetworking = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.networks,
);

export const selectTheme = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.theme ?? themeEnum.LIGHT,
);

export const selectMenuState = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.menuState,
);

export const selectCountry = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.country || ({} as Country),
);

export const selectCurrencyCode = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.currencyCode || "",
);

export const selectCurrencyInfo = createSelector(
  selectLayoutState,
  selectCurrencyCode,
  (state: LayoutState, currencyCode?: string) => {
    let today = moment().format("YYYY-MM-DD");
    return state.currencyInfo[`${currencyCode}_${today}`];
  },
);

export const selectLanguage = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.language,
);

export const selectUser = createSelector(
  selectLayoutState,
  (userState: UserState) => userState.user,
);
export const selectToken = createSelector(
  selectUser,
  (user: User) => user?.jwtToken || "",
);
