/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

/*!
 * Copyright 2020 - UR TRAVEL AND SOFTWARE SAS
 */

export enum themeEnum {
  LIGHT = 1,
  DARK = 2,
}
