/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as actions from "../store/layout.actions";
import * as selectors from "../store/layout.selectors";
import { Observable } from "rxjs";
import { Menu } from "../model/menu";
import { Network } from "../model/network";
import { themeEnum } from "../model/themeEnum";
import { Country } from "../model/country";
import { Currency } from "../model/currency";
import { MenuState } from "../model/layout.state";
import { User } from "../../shared-services/user/model/authenticate.request";
import { refreshToken } from "../store/layout.actions";

@Injectable({
  providedIn: "root",
})
export class LayoutStoreService {
  constructor(private store: Store) {}

  public getBranding(): void {
    this.store.dispatch(actions.getBranding());
  }

  public getCurrencyInfo(): void {
    this.store.dispatch(actions.getCurrencyInfo());
  }

  public selectLogo(): Observable<string> {
    return this.store.select(selectors.selectLogo);
  }

  public selectMenu(): Observable<Menu[]> {
    return this.store.select(selectors.selectMenu);
  }

  public selectNetworking(): Observable<Network[]> {
    return this.store.select(selectors.selectNetworking);
  }

  public setTheme = (theme: themeEnum): void => {
    this.store.dispatch(actions.setTheme({ theme }));
  };

  public setCountry = (country: Country): void => {
    this.store.dispatch(actions.setCountry({ country }));
  };

  public setCurrencyCode = (currencyCode: string): void => {
    this.store.dispatch(actions.setCurrencyCode({ currencyCode }));
  };

  public setLanguage = (language: string): void => {
    this.store.dispatch(actions.setLanguage({ language }));
  };

  public setMenuState = (menuState: MenuState): void => {
    this.store.dispatch(actions.setMenuState({ menuState }));
  };

  public selectMenuState(): Observable<MenuState> {
    return this.store.select(selectors.selectMenuState);
  }
  public selectTheme(): Observable<themeEnum> {
    return this.store.select(selectors.selectTheme);
  }

  public selectCountry(): Observable<Country> {
    return this.store.select(selectors.selectCountry);
  }

  public selectCurrencyCode(): Observable<string> {
    return this.store.select(selectors.selectCurrencyCode);
  }

  public selectCurrencyInfo(): Observable<Currency> {
    return this.store.select(selectors.selectCurrencyInfo);
  }

  public selectLanguage(): Observable<string> {
    return this.store.select(selectors.selectLanguage);
  }

  /*  public getCurrencyInfo(): void {
    this.store.dispatch(actions.getCurrencyInfo());
  }

  public selectNavigation(): Observable<MenuLink[]> {
    return this.store.select(selectors.selectNavigation);
  }*/

  /*
  public selectCompanyImages(): Observable<string> {
    return this.store.select(selectors.selectLogo);
  }
*/

  /*  public selecTextCompanySlide(): Observable<SlideMedia[]> {
    return this.store.select(selectors.selectTextSlide);
  }*/
  /*
  public selectCountry(): Observable<Country> {
    return this.store.select(selectors.selectCountry);
  }

  public selectCurrencyCode(): Observable<string> {
    return this.store.select(selectors.selectCurrencyCode);
  }

  public selectCurrencyInfo(): Observable<Currency> {
    return this.store.select(selectors.selectCurrencyInfo);
  }



  public setCountry(country: ICountry): Observable<ICountry> {
    this.store.dispatch(actions.setCountry({ country }));
    return this.storage.set("country", country).pipe(map(() => country));
  }

  public setCurrencyCode(
    currencyCode: string,
    updateCurrencyInfo: boolean = false
  ): Observable<string> {
    this.store.dispatch(actions.setCurrencyCode({ currencyCode }));
    updateCurrencyInfo && this.store.dispatch(actions.getCurrencyInfo());
    return this.storage
      .set("currencyCode", currencyCode)
      .pipe(map(() => currencyCode));
  }

  public setCurrencyInfo(currencyInfo: ICurrency): void {
    this.store.dispatch(actions.setCurrencyInfo({ currencyInfo }));
  }



  public getCarts(): void {
    return this.store.dispatch(actions.getCart());
  }

  public selectCart(): Observable<ShoppingCart[]> {
    return this.store.select(selectors.selectCart);
  }

  public createCart(cart: ShoppingCart, index: number): void {
    return this.store.dispatch(actions.createCart({ cart, index }));
  }

  public setCart(cart: ShoppingCart, index: number = -1): void {
    this.store.dispatch(actions.setCart({ cart, index }));
  }

  public updateCart(newCart: ShoppingCart, index: number): void {
    this.store.dispatch(actions.updateNameCart({ newCart, index }));
  }*/
  /*
  public selectHomeSection(): Observable<any[]> {
    return this.store.select(selectors.selectHomeSection);
  }*/

  /*  public selectPackageHomeDestination(): Observable<PakPackageHome[]> {
    return this.store.select(selectors.selectPackageHomeDestination);
  }

  public selectPackageHomeOneDay(): Observable<PakPackageHome[]> {
    return this.store.select(selectors.selectPackageHomeOneDay);
  }

  public selectPackageHomeEcotourism(): Observable<PakPackageHome[]> {
    return this.store.select(selectors.selectPackageHomeEcotourism);
  }

  public selectPackageHomeNational(): Observable<PakPackageHome[]> {
    return this.store.select(selectors.selectPackageHomeNational);
  }*/
  /*

  public getThereIsAReservation(
    reservationCode: string,
    lastName: string
  ): void {
    this.store.dispatch(
      actions.thereIsAReservation({ reservationCode, lastName })
    );
  }

  public setReservationState(
    thereIsAReservation: thereIsAReservationEnum
  ): void {
    this.store.dispatch(actions.setReservationState({ thereIsAReservation }));
  }
*/

  /*  public selectThereIsAReservation(): Observable<IThereIsAReservationEnum> {
    return this.store.select(selectors.selectThereIsAReservation);
  }

  public selectHotelsRandom = () =>
    this.store.select(selectors.selectHotelsRandom);*/

  /*  public getAllDataHome(): void {
    this.store.dispatch(actions.getAllDataHome());
  }

  public setLoadingHome(state: boolean): void {
    this.store.dispatch(actions.setLoadingHome({ state }));
  }*/

  /*  public selectLoadingHome = (): Observable<boolean> =>
    this.store.select(selectors.selectLoadingHome);*/
  /*
  public getCompanyImages(): void {
    return this.store.dispatch(actions.getCompanyImages());
  }*/

  /*  public getNavigation(companyId: string = "URTRAVEL"): void {
    return this.store.dispatch(actions.getNavigation({ companyId }));
  }*/

  public login(username: string, password: string): void {
    this.store.dispatch(
      actions.login({
        username,
        password,
      }),
    );
  }

  public refreshToken(): void {
    this.store.dispatch(actions.refreshToken());
  }

  public logout(): void {
    this.store.dispatch(actions.logout());
  }

  public setUser = (user: User): void => {
    this.store.dispatch(actions.setUser({ user }));
  };

  public selectToken(): Observable<string> {
    return this.store.select(selectors.selectToken);
  }

  public selectUser(): Observable<User> {
    return this.store.select(selectors.selectUser);
  }
}
