/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Menu } from "./menu";
import { Network } from "./network";
import { themeEnum } from "./themeEnum";
import { Country } from "./country";
import { Currency } from "./currency";
import { User } from "../../shared-services/user/model/authenticate.request";

export interface LayoutState {
  logo?: string;
  menu: Menu[];
  networks: Network[];
  theme?: themeEnum;
  country?: Country;
  currencyCode?: string;
  currencyInfo: { [key: string]: Currency };
  language: string;
  menuState: MenuState;
  user: User;
}

export enum MenuState {
  Opened = "opened",
  Closed = "closed",
}
