/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createReducer, on } from "@ngrx/store";
import { LayoutState, MenuState } from "../model/layout.state";
import * as actions from "./layout.actions";
import { clone, setWith } from "lodash";
import { GetBrandingResponse } from "../model/get-branding.response";
import { themeEnum } from "../model/themeEnum";
import { Country } from "../model/country";
import { Currency } from "../model/currency";
import moment from "moment";
import { UserState } from "../../routable-components/public/login/model/login.model";
import { User } from "../../shared-services/user/model/authenticate.request";

export const layoutFeatureName = "layout";
export const initialState: LayoutState = {
  menu: [],
  networks: [],
  currencyInfo: {},
  language: "es",
  menuState: MenuState.Closed,
  user: {},
};

export const layoutReducer = createReducer(
  initialState,
  on(
    actions.setBranding,
    (
      state: LayoutState,
      { branding: { menu, logo, networks } }: { branding: GetBrandingResponse },
    ) => {
      let newState = clone(state);
      newState.logo = logo;
      newState.menu = menu;
      newState.networks = networks;
      return newState;
    },
  ),
  on(actions.setTheme, (state: LayoutState, { theme }: { theme: themeEnum }) =>
    setWith(clone(state), "theme", theme, clone),
  ),
  on(
    actions.setCountry,
    (state: LayoutState, { country }: { country: Country }) =>
      setWith(clone(state), "country", country, clone),
  ),
  on(
    actions.setCurrencyCode,
    (state: LayoutState, { currencyCode }: { currencyCode: string }) =>
      setWith(clone(state), "currencyCode", currencyCode, clone),
  ),
  on(
    actions.setCurrencyInfo,
    (state: LayoutState, { currencyInfo }: { currencyInfo: Currency }) => {
      let newState = clone(state);
      let newCurrencyInfo = clone(newState.currencyInfo);
      let today = moment().format("YYYY-MM-DD");
      newCurrencyInfo[`${currencyInfo.currencyId}_${today}`] = currencyInfo;
      newState.currencyInfo = newCurrencyInfo;
      return newState;
    },
  ),
  on(
    actions.setLanguage,
    (state: LayoutState, { language }: { language: string }) =>
      setWith(clone(state), "language", language, clone),
  ),
  on(
    actions.setMenuState,
    (state: LayoutState, { menuState }: { menuState: MenuState }) =>
      setWith(clone(state), "menuState", menuState, clone),
  ),
  on(actions.setUser, (state: LayoutState, { user }: { user: User }) =>
    setWith(clone(state), "user", user, clone),
  ),
  on(actions.clearUser, () => clone(initialState)),

  /*    on(actions.setCountry, (state: LayoutState, {country}: { country: Country }) =>
          setWith(clone(state), 'country', country, clone)
      ),
      on(actions.setTheme, (state: LayoutState, {theme}: { theme: themeEnum }) =>
          setWith(clone(state), 'theme', theme, clone)
      ),
      on(actions.setCurrencyInfo, (state: LayoutState, {currencyInfo}: { currencyInfo: Currency }) =>
          setWith(clone(state), 'currencyInfo', currencyInfo)
      ),
      on(actions.setCurrencyCode, (state: LayoutState, {currencyCode}: { currencyCode: string }) =>
          setWith(clone(state), 'currencyCode', currencyCode)
      ),
      on(actions.setCart, (state: LayoutState, {cart,index}: { cart: ShoppingCart, index: number }) => {
          const newState: LayoutState = clone(state);
          if (index !== -1) {
              newState.cart = newState.cart.map((crt:ShoppingCart, ind:number) => {
                  return (index === ind) ?
                      cart : crt;
              });
          } else
              newState.cart = newState.cart.concat(cart);
          return newState;
      }),
      on(actions.setCartsCode, (state: LayoutState, {cart}: { cart: ShoppingCart[] }) => {
          const newState: LayoutState = clone(state);
          const newCarts =
              cart.filter((cart:ShoppingCart) => !newState.cart.map((r:ShoppingCart) => r.nameCart).includes(cart.nameCart))
                  .map((cart:ShoppingCart) => cart);
  
          newState.cart = [...newState.cart, ...newCarts];
          return newState;
      }),
      on(actions.setReservationState, (state: LayoutState, {thereIsAReservation}: { thereIsAReservation: thereIsAReservationEnum }) =>
          setWith(clone(state), 'thereIsAReservation', thereIsAReservation)
      ),
      on(actions.setMenu, (state: LayoutState, {menu}: { menu: MenuLink[] }) =>
          setWith(clone(state), 'menu', menu)
      ),*/
);
